<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubro"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="rubros"
                    hide-details
                    clearable
                    autofocus
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="mb-md-2"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="categorias"
        :loading="load"
        :search="search"
        sort-by="nombre"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.solicita_fe_aut`]="{ item }">
          <v-icon
            v-if="item.solicita_fe_aut == 1"
            color="success"
            title="Solicita FE autom."
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.no_paga_desc_empl`]="{ item }">
          <v-icon
            v-if="item.no_paga_desc_empl == 1"
            color="success"
            title="No comis. desc. empl."
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.solicita_serie`]="{ item }">
          <v-icon
            v-if="item.solicita_serie == 1"
            color="success"
            title="Solicita serie"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actualiza_precio_may`]="{ item }">
          <v-icon
            v-if="item.actualiza_precio_may == 1"
            color="success"
            title="Actualiza precio mayorista"
            small
          >
            fas fa-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.inhabilitado`]="{ item }">
          <span
            v-text="item.inhabilitado ? 'SI' : 'NO'"
          ></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-icon
            v-if="!item.inhabilitado"
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
            >
              fas fa-pen
            </v-icon>
            <v-icon
              v-if="!item.inhabilitado"
              color="orange"
              title="Objetivos"
              class="mr-2"
              small
              @click="abrilModalObjetivos(item)"
            >
              fas fa-bullseye
            </v-icon>
            <!-- <i class="fas fa-bullseye" fas fa-check-double></i> -->
            <BtnConfirmar
              :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
              :color="item.inhabilitado ? 'success' : 'error'"
              :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
              :texto="item.inhabilitado
                ? `¿Desea habilitar la categoria <strong>${item.nombre}</strong> del rubro <strong>${item.rubro_nombre}</strong>?`
                : `¿Desea inhabilitar la categoria <strong>${item.nombre}</strong> del rubro <strong>${item.rubro_nombre}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
            />
          </div>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <Categoria
      v-model="dialog"
      :p_nueva="nueva"
      :p_categoria="categoria"
      :p_rubros.sync="rubros"
      @editar="editar"
      @nueva="nueva_categoria"
    />
    <ModalObjetivosCategorias
      v-model="objModal.activo"
      :datos="objModal"
    />
  </v-row>
</template>

<script>
import ModalObjetivosCategorias from '../../components/generales/ModalObjetivosCategorias.vue'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import Categoria from '../../components/generales/Categoria'
import { order_list_by } from '../../util/utils'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      nueva: false,
      dialog: false,
      rubros: [],
      categorias: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'FE tipo concepto', value: 'tipo_fe_nombre' },
        { text: 'Solicita FE autom.', value: 'solicita_fe_aut', sortable: false, filterable: false, align: 'center'},
        { text: 'No comis. desc. empl.', value: 'no_paga_desc_empl', sortable: false, filterable: false, align: 'center' },
        { text: 'Solicita serie', value: 'solicita_serie', sortable: false, filterable: false, align: 'center' },
        { text: 'Actualiza precio mayorista', value: 'actualiza_precio_may', sortable: false, filterable: false, align: 'center' },
        { text: 'Inhabilitado', value: 'inhabilitado', sortable: false, filterable: false },
        { text: 'Rubro', value: 'rubro_nombre' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      categoria: {
        codigo:                     null,
        nombre:                     null,
        rubro_codigo:               null,
        tipo_fe_codigo:             null,
        solicita_fe_aut:            null,
        no_paga_desc_empl:          null,
        solicita_serie:             null,
        actualiza_precio_may:       null,
        cant_limit_anual_categoria: null,
        cant_arts_desc_emp_rubros:  null
      },
      filtro: {
        rubro: null,
        nombre: '',
        inhabilitado: 0
      },
      permiso: 0,
      objModal: {
        categoria_codigo: 0,
        categoria_nombre: '',
        rubro_codigo: 0,
        rubro_nombre: '',
        activo: false,
      },
    }
  },
  async created () {
    this.$store.state.loading = true
    this.rubros = []
    await this.$store.dispatch('rubros/get_rubros',{ familia: null,    nombre: '',   inhabilitado: 0  })
      .then((res) => {
        this.rubros = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    // ordena los rubros por nombre desc
    order_list_by(this.rubros, 'nombre');
    await this.getPermisos();
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    Categoria,
    ModalObjetivosCategorias
  },
  methods: {
    async getPermisos(){
      let res = await this.$store.dispatch('genericosAcc/getPermiso', {funcion_codigo: 166});
      if(res.exito == 0){
        return this.$store.dispatch('show_snackbar', {  text: res.msj,   color: 'error'   })
      }
      this.permiso = res.permiso;
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('categorias/habilitar', item.codigo)
        .then((res) => {
          // actualiza la categoria en la lista
          item.inhabilitado = 0
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('categorias/inhabilitar', item.codigo)
        .then((res) => {
          // actualiza la categoria en la lista
          item.inhabilitado = 1
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.categorias = []
      await this.$store.dispatch('categorias/get_categorias', this.filtro)
        .then((res) => {
          this.categorias = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    nueva_categoria(item){
      this.filtro.nombre = item.nombre
      this.buscar();
    },
    editar (item) {
      // actualiza la categoria en la lista
      let categoria = this.categorias.find(cat => cat.codigo == item.codigo)
      Object.assign(categoria, item)
    },
    open_dialog (item) {
      if (item) {
        this.nueva = false
        this.categoria = JSON.parse(JSON.stringify(item))
      } else {
        this.nueva = true
        this.categoria = {};
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        rubro: null,
        nombre: '',
        inhabilitado: 0
      }
    },
    abrilModalObjetivos(item){
      this.objModal.categoria_codigo = item.codigo;
      this.objModal.categoria_nombre = item.nombre;
      this.objModal.rubro_codigo     = item.rubro_codigo;
      this.objModal.rubro_nombre     = item.rubro_nombre;
      this.objModal.activo           = true;
    }
  }
}
</script>