<template>
  <div>
  <v-dialog
      v-model="activo"
      width="850"
      scrollable
    >
      <v-card :loading="load">
        <v-card-title class="">
          <div :style="pantallaChica ? 'font-size: 15px': 'font-size: 20px'">
            {{ pantallaChica ? `Obj. por Cat.: ${datos.categoria_nombre}` : `Objetivos por Categoría: ${datos.categoria_nombre}` }}
          </div>
          <div class="pl-2">
            <v-btn title="Nuevo" color="success" x-small fab @click="abrirModal(null)">
              <v-icon small >fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-4 py-3">
          <v-data-table
            class="cebra elevation-2"
            :headers="header"
            :items="objetivosCategorias"
            :loading="load"
            :search="search"
            dense
          >
            <!-- Buscador -->
            <template v-slot:top>
              <v-row class="d-flex justify-end pa-2" no-gutters>
                <v-col cols="6" sm="3" md="2">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </template>
            <!-- Porcentaje -->
            <template v-slot:[`item.porcentaje`]="{ item }">
              {{ item.porcentaje }}%
            </template>
            <!-- Acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- <div class="d-flex"> -->
                <v-icon
                  v-if="!item.inhabilitado"
                  color="info"
                  :title="new Date(limitFecInf) <= new Date(item.fecha_desde) && new Date(item.fecha_desde)<= new Date(limitFecSup) ? 'Editar' : (item.fecha_hasta ? 'Ver' :'Editar')"
                  class="mr-2"
                  small
                  @click="abrirModal(item)">
                  {{ new Date(limitFecInf) <= new Date(item.fecha_desde) && new Date(item.fecha_desde)<= new Date(limitFecSup) ? 'fas fa-pen' :
                   (new Date(limitFecInf) <= new Date(item.fecha_hasta) || !item.fecha_hasta ? 'fas fa-pen' :'fas fa-eye') }}
                </v-icon>
                {{ /* (new Date((new Date(item.fecha_desde)).getFullYear(),(new Date(item.fecha_desde)).getMonth(),(new Date(item.fecha_desde)).getDate()) < (new Date(limitFecInf))) */ }}
                {{ /*new Date((new Date(item.fecha_desde)).getFullYear(),(new Date(item.fecha_desde)).getMonth(),(new Date(item.fecha_desde)).getDate())*/ }}
                {{ /*new Date(limitFecInf) <= new Date(item.fecha_desde)*/ }}
                <v-icon
                  v-if="!item.fecha_hasta || (new Date(limitFecInf) <= new Date(item.fecha_desde) || new Date(limitFecSup) <= new Date(item.fecha_hasta))"
                  :color="item.inhabilitado == 0 ? 'error' : 'success'"
                  :title="item.inhabilitado == 0 ? 'Inhabilitar' : 'Habilitar'"
                  class="mr-2"
                  small @click="inhabilitarHabilitar(item)">
                  {{ item.inhabilitado == 0 ? 'fas fa-ban' : 'fas fa-check'}}
                </v-icon>
              <!-- </div> -->
            </template>
            <!-- Tiene articulos -->
            <template v-slot:[`item.tiene_articulos`]="{ item }">
              <v-icon v-if="item.tiene_articulos == 1" color="success" small >
                fas fa-check-circle
              </v-icon>
            </template>
            <!-- Fecha Desde -->
            <template v-slot:[`item.fecha_desde`]="{item}">
              {{ moment(item.fecha_desde).format('DD/MM/YY') }}
            </template>
            <!-- Fecha Hasta -->
            <template v-slot:[`item.fecha_hasta`]="{item}">
              {{ item.fecha_hasta ? moment(item.fecha_hasta).format('DD/MM/YY') : '' }}
            </template>
            <!-- No hay datos -->
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text>
                  No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- Componente de Nuevo / Editar -->
       <ModalNvoEditarObjCat
        v-model="objModal.activo"
        :datos="objModal"
        @actualizar="init"
       />
      </v-card>
    </v-dialog>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  //import BtnConfirmarVue from '../util/BtnConfirmar.vue';
  import FechaPickerVue from '../util/FechaPicker.vue';
  import SearchDataTable from '../util/SearchDataTable.vue';
  import { order_list_by_date } from '../../util/utils';
  import ModalNvoEditarObjCat from './ModalNvoEditarObjCat'
  
  export default {
    name: 'ModalObjetivosCategorias',
    props:{
      value: Boolean,
      datos:{
        type: Object,
        default: {}
      }  
    },
    computed:{
      activo:{
        get(){
          return this.datos.activo;
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    data(){
      return{
        load: false,
        search:'',
        moment: moment,
        header: [
          {text: 'Tipo', value: 'tipo_objetivo_nombre'},
          {text: 'Canal', value: 'canal_nombre'},
          {text: 'Desde', value: 'fecha_desde'},
          {text: 'Hasta', value: 'fecha_hasta'},
          {text: 'Porc.(%)', value: 'porcentaje', align: 'end'},
          {text: 'Articulos', value: 'tiene_articulos', align: 'center'},
          {text: 'Acciones', value: 'acciones', filterable: false, sortable: false},
        ],
        objetivosCategorias: [],
        objModal:{
          activo              : false,
          controlarFechas     : false,
          canal_id            : null,
          canal_nombre        : "",
          fecha_desde         : "",
          fecha_hasta         : "",
          fecha_desde_sf      : "",
          fecha_hasta_sf      : "",
          id                  : 0,
          porcentaje          : 100,
          tiene_articulos     : 0,
          tipo_objetivo_id    : null,
          tipo_objetivo_nombre: '',
          categoria_codigo    : null,
          categoria_nombre    : '',
        },
        limitFecInf: new Date((new Date).getFullYear(),(new Date).getMonth(),1), //primer día del mes actual
        limitFecSup: new Date((new Date((new Date).getFullYear(), (new Date).getMonth() + 1, 1)) - 1), //último día del mes actual,
        pantallaChica: this.$vuetify.breakpoint.xs,
      }
    },
    created(){
    },
    methods:{
      async init(){
        this.load = true;
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/iniciarForm', {categoria_codigo: this.datos.categoria_codigo});
        this.$store.state.loading = false;
        this.load = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error'  });
        }
        this.objetivosCategorias = res.objetivos;
      },
      abrirModal(item){
        this.objModal.controlarFechas       = item ? /*( item.fecha_hasta? false : */ (new Date(this.limitFecInf)) <= (new Date(item.fecha_desde)) : true;
        this.objModal.canal_id              = item && item.hasOwnProperty('canal_id') ? item.canal_id : null;
        this.objModal.canal_nombre          = item && item.hasOwnProperty('canal_nombre') ? item.canal_nombre :"";
        this.objModal.fecha_desde           = item && item.hasOwnProperty('fecha_desde') ? moment(item.fecha_desde).format('DD/MM/YYYY') : moment(new Date()).format('DD/MM/YYYY');
        this.objModal.fecha_desde_sf        = item && item.hasOwnProperty('fecha_desde') ? item.fecha_desde : (new Date());
        this.objModal.fecha_hasta           = item && item.hasOwnProperty('fecha_hasta') ? (item.fecha_hasta ? moment(item.fecha_hasta).format('DD/MM/YYYY') : '') : ''/*moment(new Date()).format('DD/MM/YYYY')*/;
        this.objModal.fecha_hasta_sf        = item && item.hasOwnProperty('fecha_hasta') ? (item.fecha_hasta ? item.fecha_hasta : '') : '';
        this.objModal.id                    = item && item.hasOwnProperty('id') ? item.id : 0;
        this.objModal.porcentaje            = item && item.hasOwnProperty('porcentaje') ? item.porcentaje : 100;
        this.objModal.tiene_articulos       = item && item.hasOwnProperty('tiene_articulos') && item.tiene_articulos == 1? 1 : 0;
        this.objModal.tipo_objetivo_id      = item && item.hasOwnProperty('tipo_objetivo_id') ? item.tipo_objetivo_id : null;
        this.objModal.tipo_objetivo_nombre  = item && item.hasOwnProperty('tipo_objetivo_nombre') ? item.tipo_objetivo_nombre : '';
        this.objModal.categoria_codigo      = this.datos.categoria_codigo;
        this.objModal.categoria_nombre      = this.datos.categoria_nombre;
        this.objModal.activo = true;
      },
      async inhabilitarHabilitar(item){
        let cad = item.inhabilitado == 0 ? 'inhabilitar' : 'habilitar';

        let modal = await this.$swal.fire({
          icon: 'question',
          title: 'Confirmar acción',
          text: `¿Está seguro de ${cad} el objetivo para la categoría: ${this.datos.categoria_nombre}?`,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (!modal.isConfirmed) return

        this.load = true;
        this.$store.state.loading = true;
        const res = await this.$store.dispatch('categorias/inhabilitarHabilitar', {comision_categoria_id: item.id, accion: item.inhabilitado == 0 ? 1 : 0});
        this.$store.state.loading = false;
        this.load = false;
        if(res.resultado == 0){
          return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error'  });
        }
        cad = item.inhabilitado == 0 ? 'inhabilitado' : 'habilitado';

        this.$store.dispatch('show_snackbar', { text: 'Objetivo '+cad+' exitosamente.', color: 'success'  });
        
        let pos = this.objetivosCategorias.indexOf(item);
        if(pos != -1){
          if(item.inhabilitado == 0 && res.fecha_hasta){
            this.objetivosCategorias[pos].fecha_hasta = res.fecha_hasta
          }
          this.objetivosCategorias[pos].inhabilitado = (item.inhabilitado == 0 ? 1 : 0);
        }

      }
    },
    components:{
      FechaPickerVue, SearchDataTable, ModalNvoEditarObjCat
    },
    watch:{
      activo: function(val){
        if(val){
          this.init();
        }
      },
      '$vuetify.breakpoint.xs'(val){
        this.pantallaChica = val;
      },
    }
  }
  </script>
  
  <style>
  
  </style>